import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/admin": [~4,[2]],
		"/admin/lexicon": [~5,[2]],
		"/admin/lexicon/[dvdId]": [~6,[2]],
		"/admin/lexicon/[dvdId]/[clipId]": [~7,[2]],
		"/admin/repl": [~8,[2]],
		"/admin/users": [~9,[2]],
		"/admin/users/[userId]": [~10,[2]],
		"/admin/users/[userId]/[threadId]": [~11,[2]],
		"/admin/users/[userId]/[threadId]/[messageId]": [~12,[2]],
		"/auth/login": [~13],
		"/auth/unauthorized": [~14],
		"/terms": [16],
		"/terms/terms-of-use": [17],
		"/t/[[threadId]]": [~15],
		"/waitlist": [~18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';