import * as Sentry from "@sentry/svelte";
import { PUBLIC_SENTRY_DSN } from "$env/static/public";
import { dev } from "$app/environment";

if (!dev) {
  // Initialize the Sentry SDK here
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.\.chatbjj\.io/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
